@import url("./css/nunito.css");

.custom-toast .Toastify__progress-bar {
  background: #11a1cd !important;
}

.fixMe_addMarginBottom{
  padding-bottom: 0px !important;
  /* background-color: red; */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F7FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cm-tab::before{
  content: "———";
  margin-right: -13px;
}

/* .cm-string{
  z-index: -1;
}

.CodeMirror-selected{
  background-color: #007500 !important;
  z-index: 100 !important;
} */

.CodeMirror-selectedtext{
  background-color: rgba(28, 47, 69, 0.6) !important;
  color: rgb(255, 255, 255) !important;
}

.MuiAutocomplete-root .MuiAutocomplete-tag {
  background-color: #11a1cd;
  color: white;
  font-weight: 600;
}

.MuiChipsInput-Chip-Editing{
  background-color: transparent !important;
  color: black !important;
  font-weight: 400 !important;
  border: 1px solid black !important;
  border-radius:11px !important;
}


.MuiChip-colorDefault{

  background-color: transparent !important;
  color: black !important;
  font-weight: 400 !important;
  border: 1px solid black !important;
  border-radius:11px !important;
}

/* .MuiButtonBase-root-MuiChip-root{
  background-color: transparent !important;
  color: black !important;
  font-weight: 400 !important;
  border: 1px solid black !important;
  border-radius:11px !important;
} */

.MuiChip-deleteIcon{
  color: black !important;
}


/* .MuiButtonBase-root-MuiChip-root {
  background-color: transparent !important;
  color: black !important;
  font-weight: 400 !important;
  border: 1px solid black !important;
  border-radius:11px !important;

} */
.MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon{
  color: #000000 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiToolbar-root-MuiTablePagination-toolbar{
  color: black !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  padding-bottom: 0% !important;
  padding-top: 0% !important;
}


.MuiPaper-root{
  background-color: white !important;
}


.MuiFormControl-root-MuiDataGrid-filterFormColumnInput input{
  color: #000000 !important;
}

select,input{
  color: #000000 !important;
}


label + .MuiInputBase-root-MuiInput-root-MuiSelect-root{
  color: #000000 !important;
}

.MuiPaper-root-MuiDrawer-paper{
  background-color: #f5f7fa !important;
}

.MuiPaper-root-MuiDialog-paper
{
  background-color:  white !important;
}

html{

  background-color: #f5f7fa;

}

.MuiTablePagination-root{
  color: #000000 !important;
}

.MuiPopper-root-MuiDataGrid-menu .MuiDataGrid-menuList{
  
  background-color: #fff;
}

.MuiDataGrid-panelFooter{
  background-color: #fff;
}
.MuiDataGrid-columnsPanel {
  background-color:white;
}


.MuiDataGrid-panelHeader input{
  color: #000000 !important;
}

.MuiDataGrid-panelHeader{
  background-color: #d8cfcf ;
  color: #000000 !important;
}

.MuiInputBase-input-MuiInput-input{
  color: black !important;
}
.MuiDataGrid-filterForm{
  background-color: #fff;
  color: black;
}



.MuiNativeSelect-select-MuiInputBase-input-MuiInput-input :focus {
  color: black !important;
 
  /* margin: 3px; */
  /* padding: 3px; */
}

.MuiInputBase-input-MuiInput-input:focus{
  background-color:#d8cfcf !important;

  color: black !important;
}

.MuiSvgIcon-root{
  color: black;
}
.MuiDataGrid-toolbarContainer{
  padding: 14px 36px 10px;
}

.MuiAutocomplete-root .MuiAutocomplete-tag{
  border: 1px solid black;
  color: black;
  border-radius:12px;
}
.MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon{
  color: black !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-hasClearIcon .css-5r88sx-MuiAutocomplete-root .MuiOutlinedInput-root{
  color: black;
}

.MuiSvgIcon-root{
  color: black;
}

.MuiFormLabel-root-MuiInputLabel-root .Mui-error{
  color: black !important;
}
.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  border: 1px solid black;
}
.MuiPaper-root-MuiDialog-paper{
  background-color: #fff;
}

.MuiPaper-root-MuiDialog-paper {
  background-color: #fff !important;
}



 .MuiYearCalendar-root{

  color: black !important;
  background-color: #fff;

}

.MuiPaper-root-MuiPickersPopper-paper{
  background-color: #fff !important;
  color: black !important;
}

.MuiDayCalendar-header .MuiDayCalendar-header {
  color: black !important;
}
.MuiButtonBase-root .MuiPickersDay-root {
  color: #000000 !important;
}


.MuiButtonBase-root-MuiPickersDay-root button{
  color: #000000 !important;
}
.MuiButtonBase-root-MuiPickersDay-root{
  color: #000000 !important;
}


.MuiButtonBase-root .MuiPickersDay-root .MuiPickersDay-dayWithMargin .MuiButtonBase-root-MuiPickersDay-root{
  color: black !important;
}

.MuiDayCalendar-monthContainer .css-12p2adl-MuiDayCalendar-monthContainer {
  color: black !important;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  color: black !important;
}


.MuiButtonBase-root .MuiPickersDay-root.Mui-selected .MuiPickersDay-dayWithMargin .MuiButtonBase-root-MuiPickersDay-root {
  background-color: black !important;
  color: white !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-selected {
  color: white !important;
}

.MuiButtonBase-root .MuiPickersDay-root .MuiPickersDay-dayWithMargin .MuiPickersDay-today .MuiButtonBase-root-MuiPickersDay-root {
  background-color: rgb(238, 238, 238) !important;
  color:black !important;
  border: 1px solid black !important;
}

.MuiPickersCalendarHeader-labelContainer{
  border: 1px solid black;
  padding: 0px 6px;

}


.MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon{
  color: black;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  color: black !important;
}

.content{

  min-width: 1217px;
}

.MuiBadge-root{
  background-color: transparent;
}

.MuiFormLabel-root-MuiInputLabel-root{
  background-color: #fff;
  padding: 0px 2px;

}


.MuiFormControl-root-MuiTextField-root .MuiInputBase-root{
  padding-bottom: 2px;
  
}

.MuiGrid-root .MuiGrid-item .MuiGrid-root {
  overflow: visible !important;
}


input{
  color: #000000 !important;
}

.MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  color: #000000 !important;
}

#webhook_uri_field{
  color: #000000 !important;
}


.MuiInputBase-input .MuiOutlinedInput-input .Mui-disabled .MuiInputBase-inputAdornedEnd .MuiInputBase-input-MuiOutlinedInput-input{
  color: #000000 !important;
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .Mui-disabled .MuiInputBase-fullWidth .MuiInputBase-formControl .MuiInputBase-adornedEnd .MuiInputBase-root-MuiOutlinedInput-root input{
  color: #000000 !important;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.Mui-disabled.MuiTypography-root {
  color: black !important;
}

.MuiPaper-root-MuiDialog-paper {
  min-height: 473px !important;
}

